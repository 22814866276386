import * as React from 'react'

const Blog = () => {
    return (
        <main>
            <h2>Blog page</h2>
        </main>
    )
}

export default Blog
